@let isTablet = (isTablet$ | async)!;
@let isHandset = (isHandset$ | async)!;
@let isScrolled = isScrolled$ | async;
@let showedToolbar = showedToolbar$ | async;
@let showedSpeedDial = showedSpeedDial$ | async;
<mat-sidenav-container>
  <mat-sidenav
    #drawer
    fixedInViewport="true"
    [attr.role]="isHandset ? 'dialog' : 'navigation'"
    [autoFocus]="false"
    [(opened)]="openSidebar"
    [mode]="isHandset || isTablet ? 'over' : 'side'"
  >
    @defer (on viewport) {
      <app-navbar />
    } @placeholder (minimum 500ms) {
      <app-navbar-skeleton />
    }
  </mat-sidenav>
  <mat-sidenav-content>
    <app-toolbar
      [@scrollUpAnimation]="isScrolled === false || showedToolbar"
      [ngClass]="{ 'is-scrolled': isScrolled }"
    />
    <main
      [@contextChange]="o | state"
      [style.viewTransitionName]="viewTransitionName()"
    >
      <router-outlet #o="outlet" />
    </main>

    @defer (when isHandset$) {
      <app-bottom
        class="bottom"
        [@scrollDownAnimation]="stable() && showedToolbar ? showedToolbar : hidden"
        [visibilitySpeedDial]="stable() && showedSpeedDial ? showedSpeedDial : hidden"
      />
    }
  </mat-sidenav-content>
</mat-sidenav-container>
