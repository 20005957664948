<mat-toolbar>
  <mat-toolbar-row>
    <button
      aria-label="Toggle sidenav"
      mat-icon-button
      type="button"
      (click)="clickNav()"
    >
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>
    <app-breadcrumb />
    <span class="spacer"></span>
    <div
      @createBox
      class="flex"
    >
      @if (deferredPrompt$ | async; as prompt) {
        <a
          mat-icon-button
          href="#install"
          (click)="install(prompt, $event)"
        >
          <mat-icon>get_app</mat-icon>
        </a>
      }
      @if (loggedIn$ | async) {
        @defer {
          <app-notification />
        }
      }
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row
    id="toolbar-tab-container"
    [style.viewTransitionName]="viewTransitionName()"
  />
</mat-toolbar>
